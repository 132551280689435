;(function($, window, document, undefined) {
  var $win = $(window);
  var $doc = $(document);
  var breakpointMobile = 767;

  $doc.ready(function() {
    $('.burger-menu').on('click', function(e){
      e.preventDefault();

      $(this).toggleClass('active');
      $('.nav').toggleClass('open');
    });

    $('.nav li a').each(function(){
      var $this = $(this);
      
      if($this.siblings('ul').length) {
        $this.parent('li').addClass('has-dd');
      }
    });

    $('.has-dd > a').on('click', function(e){
      
      var $this = $(this);

      if(!$this.hasClass('clicked') && $win.width() < breakpointMobile) {
        e.preventDefault();
      }
      
      $this.siblings('ul').slideToggle();

      $this.parent('li').toggleClass('open-dd');

      $this.parent('li').siblings().removeClass('open-dd').find('ul').slideUp();
      
      $this.parent('li').siblings().find('.clicked').removeClass('clicked');

      $this.addClass('clicked');

    });

    if(!$('.intro').length) {
      $('.wrapper').addClass('no-intro');
    }
    

    //magnific popup

    $('.btn--video').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });

    //Equalize Heights for financial

    $win.on('load resize', function() {
      if($win.innerWidth() < 1680 && $win.innerWidth() > 992) {
        var maxHeight = 0;

        $('.financial--equalize > p').each(function() {
          maxHeight = ($(this).outerHeight() > maxHeight) ? $(this).outerHeight() : maxHeight;
        });

        $('.financial--equalize > p').css({
          'min-height': maxHeight
        });

      }
    });

  });
})(jQuery, window, document);
